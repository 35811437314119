import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { httpClient } from '../../../appUtility/Api';
import BreadCrumb from '../../../Components/BreadCrumb';
import CausesContent from '../../../Components/Causes/CausesContent';
import CausesTop from '../../../Components/Causes/CausesTop';
import GiveHours from '../../../Components/CausesNew/GiveHours';
import MoreLinks from '../../../Components/CausesNew/MoreLinks';
import AllCauses from '../../../Components/Causes/AllCauses'
import AboutTimeVideo from '../../../Components/CausesNew/AboutTimeVideo';
import { ImageOutlined } from '@mui/icons-material';
import Events from '../../../Components/CausesNew/Events'
import UpcomingEvents from '../../../Components/CausesNew/UpcomingEvents'
import TrendingCauses from '../../../Components/CausesNew/TrendingCauses'
import Environment from '../../../Components/Causes/Environment';
// import CausesNewsFeed from '../../../Components/CausesNew/CausesNewsFeed';

// import HelmetMetaData from '../../../Components/HelmetMetaData';

const CauseSinglePage = () => {
  const location = useLocation();
  const {id} = useParams();
  const [category, setCategory] = useState([]);
  // const [allData,setAllData] = useState(location && location.state);
  const [causeID, setCauseID] = useState("");
  const [allData, setAllData] = useState("");
  const [causeLists, setCauseLists] = useState("");
  const [causeNews, setCauseNews] = useState("");

  // console.log('params id', id);

  useEffect(() => {
    getCauseSingle(id);
    getFeaturedCategories();
  }, [id]);

  useEffect(() => {
    if(allData){
      getCausesList(allData);
      getCauseNews(allData);
    }
  },[allData])

// console.log('location.state',location.state)

  const getCauseSingle = (id) => {
    httpClient.get(`charity-cause/${id}`).then(({ data }) => {
      if (data.success) {
        setAllData(data.data)
        if(location && location.state.give === true){
          document.getElementById('give_hours').scrollIntoView({ behavior: "smooth" });
        }else{
          window.scrollTo({top: 0, behavior: 'smooth'});
        }
      }
    })
  }
  //console.log("data", allData)

  const getCausesList = (data) => {
      httpClient.get(`cause-category/${data.categoryID.slug}`).then(({ data }) => {
        if (data.success) {
          setCauseLists(data.data)
        }
      })
  }

  const getCauseNews = (allData) => {
   
    httpClient.get(`news?causeID=${allData && allData.causeID}`).then(({ data }) => {
      if (data.success) {
        setCauseNews(data.data)
      }
    })
}


  const handleRefetch = () => {
    getCauseSingle(id);
    // getFeaturedCategories();
  };
  

  const getFeaturedCategories = () => {
    httpClient.get("featured-cause-category").then(({ data }) => {
      if (data.success) {
        setCategory(data.data);
      }
    });
  };

  //console.log('causeLists<<<<<<<<<<<<<<<<', causeNews)
 
  return (
    <div>
      {/* <HelmetMetaData
        title={allData && allData.name}
        description={allData && allData.shortDescription.substring(1,allData.shortDescription.length -1)}
        image={allData && allData.image}
      /> */}
      
      <BreadCrumb header={allData && allData.name} subHeader1="Causes" Link1="/causes" subHeader2={allData && allData.name} />
      <AboutTimeVideo causeID={allData && allData.causeID} header={allData && allData.name} description={allData && allData.shortDescription || ""} image={allData && allData.image} hours={allData && allData.hours} members={allData && allData.totalMembers} organization={allData && allData.organizationID.orgName} showFav={true} allData={allData && allData} />
      {/* <CausesTop causeID={causeID} header={allData && allData.name} description={allData && allData.shortDescription || ""} image={allData && allData.image} hours={allData && allData.hours} members={allData && allData.totalMembers} organization={allData && allData.organizationID.orgName} showFav={true} allData={allData && allData} /> */}
      {/* <CausesContent content={allData && allData.longDescription || ""} /> */}
      <Events />
      
      <UpcomingEvents newsList = {causeNews && causeNews}  />
      <GiveHours allData={allData && allData} handleRefetch={handleRefetch} />
      <MoreLinks allData={allData && allData} />
      {/* <CausesNewsFeed newsList = {causeNews && causeNews}  /> */}
      <TrendingCauses />

      {category && category.map((cats, index) =>
        cats.categoryID === allData?.categoryID?.categoryID && index < 6 && (
          <Environment category={cats} key={index} allData={causeLists} handleRefetch={handleRefetch} />
        )
      )}


    </div>
  )
}

export default CauseSinglePage
import React, { useEffect, useState, useRef } from "react";
// import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Snackbar,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { Camera, Close, Error } from "@mui/icons-material";
import { httpClient } from "../../appUtility/Api";
import moment from "moment/moment";
import MuiAlert from "@mui/material/Alert";
import Webcam from "react-webcam";
import Tesseract from 'tesseract.js';

const RedButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.red,
  color: "#fff",
  padding: "10px 40px",
  outline: "none",
  borderRadius: "25px",
  border: "1px solid transparent",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid ${theme.palette.primary.red} !important`,
    background: "transparent !important",
    color: `${theme.palette.primary.red} !important`,
  },
  "& span": {
    color: "#fff",
  },
}));

const CardScanner = ({ onCardDetailsExtracted, handleCloseCamera }) => {
  const webcamRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    recognizeText(imageSrc);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const recognizeText = (image) => {
    setLoading(true);
    Tesseract.recognize(
      image,
      'eng',
      { logger: (m) => console.log(m) }
    ).then(({ data: { text } }) => {
      const numbers = text.match(/\d+/g);
      if (numbers) {
        const cardDetails = numbers.join(' ');
        onCardDetailsExtracted(cardDetails);
        handleCloseCamera();
      } else {
        handleCloseCamera();
        setMessage("No numbers detected");
        setMessageState("error");
      }
      setLoading(false);
    });
  };

  return (
    <>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', gap: '10px' }}>
        <div style={{ gridColumn: 'span 6', position: 'relative' }}>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={380}
            height={300}
          />
          <Tooltip title={"Close camea"}>
            <Close
              onClick={handleCloseCamera}
              style={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer' }}
            />
          </Tooltip>
        </div>
        <div style={{ gridColumn: 'span 6', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <RedButton variant="contained" color="primary" onClick={capture}>Capture</RedButton>
        </div>
        {loading && <p style={{ gridColumn: 'span 12' }}>Loading...</p>}
      </div>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MainDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "20px",
    borderRadius: "25px",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },
  "& h1": {
    margin: "0",
    fontWeight: "300",
  },
  "& a": {
    textDecoration: "underline",
    color: "blue",
  },
  [theme.breakpoints.down("md")]: {
    "& h1": {
      margin: "0",
      fontSize: "28px",
      fontWeight: "300",
    },
  },
}));

const Logo = styled("div")(({ theme }) => ({
  transform: "rotate(90deg)",
  display: "inline-block",
  paddingTop: "10px",
  "& span": {
    background: theme.palette.primary.red,
    padding: "5px",
    display: "inline-block",
    borderRadius: "50%",
    margin: "12px 3px px 3px",
    [theme.breakpoints.down("md")]: {
      margin: "0",
      padding: "4px",
    },
  },
}));

const MainTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "Epilogue",
  "& p1": {
    fontSize: "40px",
    fontWeight: "350",
  },
  "& svg": {
    cursor: "pointer",
    position: "relative",
    top: "-35px",
    right: "-15px",
  },
  [theme.breakpoints.down("md")]: {
    "& p1": {
      fontSize: "26px",
    },
  },
}));

const GreyButton = styled(Button)(({ theme }) => ({
  background: "#ec3424",
  padding: "5px 25px",
  color: "#fff",
  ouline: "none",
  borderRadius: "25px",
  border: "1px solid #777",
  marginRight: "10px",
  cursor: "pointer",
  transition: "0.3s",
  textTransform: "none",
  "&:hover": {
    background: "#777",
    color: "#fff",
  },
  "& span": {
    color: "#777",
  },
}));

const GreyButton2 = styled(Button)(({ theme }) => ({
  background: "#777",
  padding: "5px 25px",
  color: "#fff",
  ouline: "none",
  borderRadius: "25px",
  border: "1px solid #777",
  marginRight: "10px",
  cursor: "pointer",
  transition: "0.3s",
  "&:hover": {
    background: "#777",
    color: "#fff",
  },
  "& span": {
    color: "#fff",
  },
}));

const CardBox = styled(Box)(({ theme }) => ({
  padding: "10px",
  border: "1px solid #999",
  borderRadius: "25px",
  marginBottom: "20px",
  width: "70%"
}));


///
const MainCheckout = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "5px 0",
  // margin:"0 20px",
  borderBottom: "2px solid #D3D3D3",
  // width:"100%",
  fontWeight: "500",
  "& span1": {
    color: "grey",
  },
}));

const MainCheckout1 = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "5px 0",
  // margin:"0 20px",
  borderBottom: "1px solid red",
  borderTop: "1px solid red",
  // width:"100%",
  fontWeight: "500",
  "& span1": {
    color: "grey",
  },
  "& img": {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
  },
}));

const CheckoutDivDivider = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "5px 0",
  borderBottom: "2px dashed #D3D3D3",
  fontWeight: "500",
  "& span1": {
    color: "grey",
  },
  "& span2": {
    color: "grey",
  },
  // paddingBottom: "60px",
}));

const CheckoutDivDivider1 = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "5px 0",

  fontWeight: "500",
  "& span1": {
    color: "grey",
  },
  // paddingBottom: "60px",
}));

const CheckoutDivDividerBtn = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "5px 0",
  borderBottom: "1px dashed #777",
  fontWeight: "500",
}));

const CheckoutDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "5px 0",
}));

///


const StripeForm = (props) => {
  const login = localStorage.getItem("login");
  const loginData = JSON.parse(login);

  const [dialog, setDialog] = useState({
    open: true,
    success: false,
  });
  //console.log("prppp", props);
  const [open, setOpen] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [loading, setLoading] = useState(false);
  const [cardDetails, setCardDetails] = useState('4242 4242 4242 4242 12/25 123');

  useEffect(() => {
    props.sendToStripe(dialog);
  }, [dialog]);

  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleSuccess = () => {
    setDialog({
      success: true,
      open: false,
    });
  };

  const stripe = useStripe();
  const elements = useElements();

  const handleCamera = () => {
    setOpenCamera(true);

  };

  const handleCloseCamera = () => {
    setOpenCamera(false);

  };

  const handleCardDetailsExtracted = (details) => {
    setCardDetails(details);
    // Optionally, you can use extracted card details here.
    // You can pre-fill card element or process the details further.
  };
  //console.log("card-details", cardDetails);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    //console.log("step1");
    if (elements == null) {
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      setOpen(true);
      setMessageState("error");
      setMessage(error.message);
      setLoading(false);
    }

    const { token, error1 } = await stripe.createToken(elements.getElement(CardElement));
    if (error1) {
      setOpen(true);
      setMessageState("error");
      setMessage(error1.message);
      setLoading(false);
    }

    // console.log("err", error);

    //console.log("payment", paymentMethod);
    //console.log("token", token);

    if (paymentMethod && token) {

      let card = {
        cardType: paymentMethod.type,
        expiryMonth: paymentMethod.card.exp_month.toString(),
        expiryYear: paymentMethod.card.exp_year.toString(),
        last4Digits: paymentMethod.card.last4,
        paymentToken: paymentMethod.id,
        stripeToken: token.id,
      };
      let donations = {
        donationFrequency: props.giveHours.frequency,
        donationHour: parseInt(props.giveHours.hours),
        donationStartDate: props.giveHours.startDate,
        donationsDay: props.giveHours.donationDay,
        donationAmount: props.donationDetails.donatedAmount,
        taxamount: props.donationDetails.taxamount,
        amountWithStripeFee: props.donationDetails.amountWithStripeFee,
        amount: loginData?.member.includeFeesOnDonation == "1" ? props.donationDetails.amountExcludeFees : props.donationDetails.amount,
      };

      var formDataUpdate = new FormData();
      formDataUpdate.append("newCauseID", (props.giveHours.newCauseID || ""));
      formDataUpdate.append("orgID", props.giveHours.orgID);
      if (props.loginData === null) {
        formDataUpdate.append("guestToken", props.guestToken);
        formDataUpdate.append("email", props.email);
      }
      formDataUpdate.append("causeID", props.giveHours.causeID);
      formDataUpdate.append("card[cardType]", card.cardType);
      formDataUpdate.append("card[expiryMonth]", card.expiryMonth);
      formDataUpdate.append("card[expiryYear]", card.expiryYear);
      formDataUpdate.append("card[last4Digits]", card.last4Digits);
      formDataUpdate.append("card[paymentToken]", "12345");

      formDataUpdate.append("card[stripeToken]", card.stripeToken);

      formDataUpdate.append("donations[donationFrequency]", donations.donationFrequency);
      formDataUpdate.append("donations[donationHour]", donations.donationHour);
      formDataUpdate.append("donations[donationStartDate]", donations.donationStartDate);
      formDataUpdate.append("donations[donationsDay]", donations.donationsDay);
      formDataUpdate.append("donations[donationAmount]", donations.donationAmount);
      formDataUpdate.append("donations[taxamount]", donations.taxamount);
      formDataUpdate.append("donations[amountWithStripeFee]", donations.amountWithStripeFee);
      formDataUpdate.append("donations[amount]", donations.amount);



      var formDataMake = new FormData();
      formDataMake.append("causeID", props.giveHours.causeID);
      formDataMake.append("orgID", props.giveHours.orgID);
      if (props.loginData === null) {
        formDataMake.append("guestToken", props.guestToken);
        formDataMake.append("email", props.email);
      }
      formDataMake.append("card[cardType]", card.cardType);
      formDataMake.append("card[expiryMonth]", card.expiryMonth);
      formDataMake.append("card[expiryYear]", card.expiryYear);
      formDataMake.append("card[last4Digits]", card.last4Digits);
      formDataMake.append("card[paymentToken]", "12345");

      formDataMake.append("card[stripeToken]", card.stripeToken);

      formDataMake.append("donations[donationFrequency]", donations.donationFrequency);
      formDataMake.append("donations[donationHour]", donations.donationHour);
      formDataMake.append("donations[donationStartDate]", donations.donationStartDate);
      formDataMake.append("donations[donationsDay]", donations.donationsDay);
      formDataMake.append("donations[donationAmount]", donations.donationAmount);
      formDataMake.append("donations[taxamount]", donations.taxamount);
      formDataMake.append("donations[amountWithStripeFee]", donations.amountWithStripeFee);
      formDataMake.append("donations[amount]", donations.amount);



      props.giveHours.ruleID ?

        httpClient
          // .post(`update-payment/${props.giveHours.ruleID}`, {
          //   causeID: props.giveHours.causeID,
          //   orgID: props.giveHours.orgID,
          //   donations: donations,
          //   card: card,
          //   newCauseID: props.giveHours.newCauseID || ''
          // })
          .post(`update-payment/${props.giveHours.ruleID}`, formDataUpdate)
          .then(({ data }) => {

            if (data.success) {
              setOpen(true);
              setMessageState("success");
              setMessage("Payment successfull!");
              setLoading(false);
              setTimeout(() => {
                handleSuccess();
              }, 1500);
            } else {
              setOpen(true);
              setMessageState("error");
              setMessage(data.message);
              setLoading(false);
            }
          }).catch(err => {
            setOpen(true);
            setMessageState("error");
            setMessage("Server Error!");
            setLoading(false);
          })
        :
        httpClient
          // .post("make-payment", {
          //   causeID: props.giveHours.causeID,
          //   orgID: props.giveHours.orgID,
          //   donations: donations,
          //   card: card,
          // })
          .post("make-payment", formDataMake)
          .then(({ data }) => {

            if (data.success) {
              setOpen(true);
              setMessageState("success");
              setMessage("Payement Successful");
              setLoading(false);
              setTimeout(() => {
                handleSuccess();
              }, 1500);
            } else {
              setOpen(true);
              setMessageState("error");
              setMessage(data.message);
              setLoading(false);
            }
          }).catch(err => {
            setOpen(true);
            setMessageState("error");
            setMessage("Server Error!");
            setLoading(false);
          })
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   setTimeout(() => {
  //     setOpen(true);
  //     setMessageState("success");
  //     setMessage("Payment successfull!");
  //     setLoading(false);
  //     setTimeout(() => {
  //       handleSuccess();
  //     }, 1500);
  //   }, 1500);
  // };

  //console.log("propsss payment", props);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (

    <MainDialog
      open={dialog.open}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="md"
    >
      <Box p={1} sx={{ border: "1px solid black", backgroundColor: "#f5f5f5", borderRadius: "10px" }}>
        <MainTitle id="alert-dialog-title">
          <div>
            <Logo>
              <span></span> <span></span>
            </Logo>{" "}
            <p1>Setup your regular donation </p1>
          </div>
          <Close onClick={handleCloseDialog} />
        </MainTitle>
        <DialogContent>

          {/* <p>
          Your time is valuable, help make a difference in the world by
          donating an hour of it.
        </p> */}
          {/* {(incomeDetails.hourlyIncome * donationDetails.hours).toFixed(2)}{" "} */}
          {/* <p>
            "If you give {props?.donationDetails.hours} hours {props?.time}, starting from{" "}
            {moment(props?.donationDetails.startDate).format("ddd, MMM Do YYYY")}, you
            will be donating $
           
            {props?.loginData?.member.includeFeesOnDonation == "1"
              ? props?.donationDetails.donatedAmount
              : props?.donationDetails.amount}{" "}
            {props?.time == "daily" ? "each day" : props.time}."
          </p> */}
          <p>
            By clicking <strong>Donate</strong> you confirm to give the nominated value your <b><i>working hour plus fees and
              transaction costs.</i></b> </p>

          <p>Starting from {" "}<b>
            {moment(props?.donationDetails.startDate).format("ddd, MMM Do YYYY")}</b> you will be donating:</p>

          {/* <b>${props?.loginData?.member.includeFeesOnDonation == "1"
            ? props?.donationDetails.donatedAmount
            : props?.donationDetails.amount}{" "}</b>
          {props?.time == "daily" ? "each day" : props.time} to{" "}
          <b>{props?.donationDetails.causeName}</b>. */}

          <MainCheckout>

          </MainCheckout>

          <CheckoutDivDivider>
            <div></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "250px",
              }}
            >
              <span1>Donation amount</span1>
              <span><b>

                ${props.donationDetails.donatedAmount}
              </b></span>
            </div>
          </CheckoutDivDivider>

          <CheckoutDivDivider>
            <div></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "250px",
              }}
            >
              <span1>Platform Fee</span1>
              <span2>

                ${(
                  parseFloat(props.donationDetails.applicationFee) +
                  (props.donationDetails.amountWithStripeFee - props.donationDetails.donatedAmount)
                ).toFixed(2)}

              </span2>
            </div>
          </CheckoutDivDivider>

          <MainCheckout>
            <div></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "250px",
              }}
            >
              <span1>Tax</span1>
              <span><b>
                ${props.donationDetails.taxamount}</b>
              </span>
            </div>
          </MainCheckout>

          <CheckoutDivDivider1>
            <div></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "250px",
              }}
            >
              <span1>Total</span1>
              <span><b>
                ${props.donationDetails.amount}
              </b></span>
            </div>
          </CheckoutDivDivider1>

          <CheckoutDivDivider1>
            <div></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "250px",
              }}
            >
              <span></span>
              <span><b>
                {props.giveHours.frequency?.charAt(0).toUpperCase() + props.giveHours.frequency?.slice(1)} To:
              </b></span>

            </div>
          </CheckoutDivDivider1>

          <MainCheckout1>
            <img src={props.giveHours.causeImage} style={{ marginRight: '10px', opacity: '100%', constraint: '100%' }} />&nbsp;&nbsp;
            {props.giveHours.causeName}
          </MainCheckout1>



          {/* <p>Remember to share your support for a cause and help us inspire more people, to give more
          money, to more charities, more often.</p> */}

          <p><strong>Enter your card details</strong></p>
          {/* <Tooltip title={"Open camera"}>
          <Camera onClick={handleCamera} />
        </Tooltip> */}
          <CardBox>

            <CardElement options={{ hidePostalCode: true }} />
          </CardBox>
          {openCamera &&
            <CardScanner onCardDetailsExtracted={handleCardDetailsExtracted} handleCloseCamera={handleCloseCamera} />
          }
          <Box textAlign={"right"}>
            {loading ? (
              <GreyButton2>
                <CircularProgress
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />{" "}
                <span>Loading</span>
              </GreyButton2>
            ) : (
              <GreyButton onClick={handleSubmit} disabled={!stripe || !elements}>
                Donate
              </GreyButton>
            )}
          </Box>

        </DialogContent>
      </Box>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </MainDialog>

  );
};

export default StripeForm;
